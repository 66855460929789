// Roles
export const ADMIN_ROLE = 'ADMIN';
export const REFERENCER_ROLE = 'REFERENCER';
export const CONTRIBUTOR_ROLE = 'CONTRIBUTOR';

// Last error storage key
export const LAST_ERROR_STORAGE_KEY = 'lastError';

// Angular Validators
export const ZIP_PATTERN = '[0-9]{5}';
export const LATITUDE_PATTERN = '(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,16})?))';
export const LONGITUDE_PATTERN = '(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,16})?))';
export const MAX_COORDS_SIZE = 8;
export const USERNAME_PATTERN = '[a-z0-9]+';

// Leaflet
export const ATTRIBUTION = 'attributionControl';
export const ZOOM = 'zoomControl';
export const DEFAULT_ZOOM = 19;
export const MAX_ZOOM = 19
export const TILE_SIZE = 256;
export const LAYER_CONTROL_BASE = 'leaflet-control-layers-base';
export const LAYER_CONTROL_OVERLAY = 'leaflet-control-layers-overlays';