import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Contribution } from 'src/app/model/contrib.model';
import { environment } from 'src/environments/environment';
import { Tile } from 'src/app/model/tile.model';

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  private BASE_API = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createContributions(contributions: Contribution[]): Observable<Contribution[]> {
    return this.http.post<Contribution[]>(`${this.BASE_API}/api/contributions`, contributions);
  }

  getNext(lastEvaluatedKey: string, wanted: string): Observable<Tile> {
   return this.http.get<Tile>(`${this.BASE_API}/api/contributions?lastEvaluatedKey=${lastEvaluatedKey}&wanted=${wanted}`);
  }

  getAllContribs(): Observable<{Items:Tile[]}> {
    return this.http.get<{Items:Tile[]}>(`${this.BASE_API}/api/contributions?wanted=all`);
  }

  resolveContribution(contribution: Contribution, hasPool: boolean, geojson: any): Observable<Contribution> {
    return this.http.put<Contribution>(`${this.BASE_API}/api/contributions/${contribution.tile.id}`, { ...contribution, hasPool, geojson });
  }

  askAi(contribution: Contribution): Observable<string> {
    return this.http.post<string>(`${this.BASE_API}/api/sagemaker`, contribution);
  }

  getLabels(): Observable<Array<{name: string, strokeStyle: string, fillStyle: string}>> {
    return this.http.get<{labels: Array<{name: string, strokeStyle: string, fillStyle: string}>}>('./assets/data/prediction_label_id.json').pipe(map((data) => data.labels));
  }
}
