import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tile } from 'src/app/model/tile.model';

@Injectable()
export class ImageService {

  constructor(private httpClient: HttpClient) { }

  getImage(tile: Tile | string): Observable<Blob> {
    if (tile instanceof Tile)
      return this.httpClient.get(`https://mt2.google.com/vt/lyrs=s&x=${tile.x}&y=${tile.y}&z=${tile.z}`, { responseType: 'blob' });
    else if(typeof tile === "string")
      return this.httpClient.get(tile, { responseType: 'blob' });
    else
      return of(null);
  }

}
